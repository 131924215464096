<template>
  <step-lesson-layout :title="$t('module2.part3.lesson1.title')">
    <template v-slot:content>
      <!-- INTRO -->
      <lesson-video-block :button-label="$t('module2.part3.lesson1.videoButton')"
        video="/videos/oscar-cee-controle-operation.mp4"
        subtitles="/subtitles/oscar-cee-controle-operation.vtt"
        :poster="require('@/assets/module2/part3/thumbnail-controles.svg')"
        :enable-resume="false">
      </lesson-video-block>

      <!-- BLOCK1 - Type controles -->
      <div class="app-text-block">
        <p class="title">{{ $t('module2.part3.lesson1.block1.title') }}</p>

        <div class="controle-tabs-wrapper image-tabs-wrapper">
          <app-tabs :tabs="controleTabs">
            <template v-slot:tab="{ name, image, isCurrent }">
              <div class="controle-tab image-tab" :class="{ current: isCurrent }">
                <img :src="image" :alt="name">
              </div>
            </template>
            <template v-slot:content="{ name }">
              <div class="controle-tab-content image-tab-content" v-if="name === 'dossier'">
                <p class="title">{{ $t('module2.part3.lesson1.block1.tab1.title') }}</p>
                <ul>
                  <li>{{ $t('module2.part3.lesson1.block1.tab1.content1') }}</li>
                  <li v-html="$t('module2.part3.lesson1.block1.tab1.content2')"></li>
                </ul>
              </div>
              <div class="controle-tab-content image-tab-content" v-else-if="name === 'qualite'">
                <p class="title">{{ $t('module2.part3.lesson1.block1.tab2.title') }}</p>
                <p class="paragraphe"><b>{{ $t('module2.part3.lesson1.block1.tab2.content1') }}</b></p>
                <ul>
                  <li v-html="$t('module2.part3.lesson1.block1.tab2.content2')"></li>
                  <li>{{ $t('module2.part3.lesson1.block1.tab2.content3') }}</li>
                </ul>
              </div>
            </template>
          </app-tabs>
        </div>
      </div>

      <!-- BLOCK2 - Exemples d'anomalies -->
      <div class="app-text-block">
        <p class="title">{{ $t('module2.part3.lesson1.block2.title') }}</p>
        <p class="paragraphe">{{ $t('module2.part3.lesson1.block2.content') }}</p>

        <div class="lesson-level-block example-block">
          <div v-for="example in examples"
            :key="example"
            class="level"
            :class="{ [example.name]: true }"
          >
            <div class="head">
              <img :src="example.image" :alt="example.name">
              <div class="head-content">
                <p class="label">{{ example.label }}</p>
                <p class="title">{{ example.title }}</p>
              </div>
            </div>
            <div class="paragraphe">
              <p class="label">{{ $t('module2.part3.lesson1.block2.examples.label') }}</p>
              <p>
                {{ example.content }}
              </p>
            </div>
          </div>
        </div>

      </div>

      <!-- BLOCK3 - anomalies courantes -->
      <div class="app-text-block">
        <p class="title">{{ $t('module2.part3.lesson1.block3.title') }}</p>
        <div class="paragraphe-alt">
          <p class="sub-title">{{ $t('module2.part3.lesson1.block3.subTitle1') }}</p>
          <p class="content" v-html="$t('module2.part3.lesson1.block3.content1')"></p>
        </div>
        <div class="training-question-block">
          <p class="training-question-title">{{ $t('module2.part3.lesson1.block3.trainingLabel') }}</p>
          <p class="training-question-label">{{ $t('module2.part3.lesson1.block3.trainingQuestion') }}</p>
          <div class="training-question-content">
            <quizz-image-only :answers="quizzAnswers" :right-answer="quizzRightAnswer"></quizz-image-only>
          </div>
        </div>
        <div class="paragraphe-alt">
          <p class="sub-title">{{ $t('module2.part3.lesson1.block3.subTitle2') }}</p>
          <p class="content" v-html="$t('module2.part3.lesson1.block3.content2')"></p>
        </div>
        <div class="paragraphe-alt">
          <p class="sub-title">{{ $t('module2.part3.lesson1.block3.subTitle3') }}</p>
          <p class="content" v-html="$t('module2.part3.lesson1.block3.content3')"></p>
        </div>
      </div>

      <!-- END BLOCK -->
      <div class="app-decoration-block end-block">
        <p class="decoration-block-content">
          {{ $t('module2.part3.lesson3.endBlock') }}
        </p>
        <app-button-layout @click="goToNextStep">
          {{ $t('global.navigation.resume') }}
        </app-button-layout>
        <app-button-layout color="alternate-secondary" @click="resumeLater">
          {{ $t('global.navigation.resumeLater') }}
        </app-button-layout>
      </div>
    </template>
  </step-lesson-layout>
</template>

<script>
import StepLessonLayout from '@/components/training/StepLessonLayout'
import LessonVideoBlock from '@/components/training/LessonVideoBlock'
import AppTabs from '@/components/AppTabs'
import QuizzImageOnly from '@/components/training/QuizzImageOnly'
import AppButtonLayout from '@/components/buttons/AppButtonLayout'
import useTrainingUtils from '@/utils/useTrainingUtils'

export default {
  name: 'Module2Part3Lesson1',
  components: { AppButtonLayout, StepLessonLayout, LessonVideoBlock, AppTabs, QuizzImageOnly },
  emits: ['next-tab'],
  setup () {
    const { resumeLater, goToNextStep } = useTrainingUtils()
    return { resumeLater, goToNextStep }
  },
  data () {
    return {
      controleTabs: [
        {
          name: 'dossier',
          image: require('@/assets/module2/part3/illu-controle-dossier.svg')
        },
        {
          name: 'qualite',
          image: require('@/assets/module2/part3/illu-controle-site.svg')
        }
      ],
      examples: [
        {
          name: 'level-min',
          label: this.$t('module2.part3.lesson1.block2.examples.example1.label'),
          title: this.$t('module2.part3.lesson1.block2.examples.example1.title'),
          content: this.$t('module2.part3.lesson1.block2.examples.example1.content'),
          image: require('@/assets/module2/part3/illu-disqualif.svg')
        },
        {
          name: 'level-max',
          label: this.$t('module2.part3.lesson1.block2.examples.example2.label'),
          title: this.$t('module2.part3.lesson1.block2.examples.example2.title'),
          content: this.$t('module2.part3.lesson1.block2.examples.example2.content'),
          image: require('@/assets/module2/part3/illu-corriger.svg')
        }
      ],
      quizzAnswers: [
        {
          name: 'low',
          label: this.$t('module2.part3.lesson1.block3.trainingAnswer1'),
          image: require('@/assets/module2/part3/national-ener-preca-low.svg')
        },
        {
          name: 'medium',
          label: this.$t('module2.part3.lesson1.block3.trainingAnswer2'),
          image: require('@/assets/module2/part3/national-ener-preca-medium.svg')
        },
        {
          name: 'high',
          label: this.$t('module2.part3.lesson1.block3.trainingAnswer3'),
          image: require('@/assets/module2/part3/national-ener-preca-high.svg')
        }
      ],
      quizzRightAnswer: 'medium'
    }
  }
}
</script>

<style lang="scss" scoped>
.app-text-block {
  padding: 0 $space-s;
  > .title {
    padding: 0 $space-sm;
  }
  .paragraphe-alt {
    margin-top: $space-m;
  }
  .training-question-block {
    margin-top: $space-m;
  }
}
.controle-tabs-wrapper {
  .controle-tab {
    height: 160px;
  }
  .controle-tab-content {
    padding: $space-sm;
    .title {
      margin-bottom: $space-s;
      font-size: $fz-xl;
    }
    .paragraphe {
      font-weight: $fw-m;
      margin-bottom: $space-s;
    }
    ul {
      font-weight: $fw-m;
      color: $c-text-dark;
      font-family: $ff-text;
      margin: 0;
      padding: 0 $space-sm;
      li + li {
        margin-top: $space-s;
      }
    }
  }
}
</style>

<style lang="scss">
.controle-tabs-wrapper {
  .app-tabs {
    .tabs-wrapper .tabs {
      display: grid;
      gap: 2px;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
.example-block {
  margin-top: $space-m;
  .paragraphe .label {
    color: $c-text-lighter;
    font-size: $fz-s;
    font-weight: $fw-l;
    text-transform: uppercase;
  }
}
</style>
